import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
// import { CopyBlock, dracula } from "react-code-blocks";
//

import { Image } from "../../../globals/UIKit";
import { COLORS } from "../../../globals/designSystem";
import { StyledSubTitle2, StyledDesc1, StyledDesc2 } from "../../shared/StyledTypography";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import { asc, removePaddBottom } from "../../shared/CommonStyled";
import { mq } from "../../../globals/utils";

// import peterDowns from "../images/peter-downs.png";
import pipeWhite from "../images/pipe-white.svg";

const StyledSectionWrapperBlue = styled(StyledSectionWrapper)`
  padding: 120px 0;
  background-color: ${COLORS.blue_100};
  ${mq.between("md", "lg")} {
    padding: 70px 0;
  }
  ${mq.below.md} {
    padding: 50px 0;
  }
`;

const StyledCaseStudyWrapper = styled.div`
  border: 2px solid ${COLORS.blue_80};
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 50px;
  width: 85%;
  margin: 0 auto;
  .brandImg {
    padding-bottom: 24px;
  }
  .displayFlex {
    display: flex;
    align-items: flex-end;
  }
  .textUppercase {
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-left: auto;
    a {
      display: flex;
      align-items: center;
      .arrow {
        display: flex;
      }
    }
  }
  ${mq.between("md", "lg")} {
    width: 100%;
    grid-gap: 40px;
  }
  ${mq.below.md} {
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    .displayFlex {
      display: block;
      .textUppercase {
        padding-top: 12px;
      }
    }
  }
`;
const ChevronRight = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.28859 7.20978C8.89859 7.59978 8.89859 8.22978 9.28859 8.61978L13.1686 12.4998L9.28859 16.3798C8.89859 16.7698 8.89859 17.3998 9.28859 17.7898C9.67859 18.1798 10.3086 18.1798 10.6986 17.7898L15.2886 13.1998C15.6786 12.8098 15.6786 12.1798 15.2886 11.7898L10.6986 7.19978C10.3186 6.81978 9.67859 6.81978 9.28859 7.20978Z"
        fill="#fff"
      />
    </svg>
  );
};
const CaseStudyWrapper = ({ removeBottomPadd }) => {
  return (
    <StyledSectionWrapperBlue css={removeBottomPadd ? removePaddBottom : null}>
      <StyledContainerWrapper>
        <StyledCaseStudyWrapper>
          <div className="profileImg" css={asc}>
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1672904432/main-web/peter-downs-3a630c101ee0f78b2d151da655eea956_nm7rhm.jpg"
              alt="Peter Downs"
            />
          </div>
          <div css={asc}>
            <div className="brandImg">
              <Image src={pipeWhite} alt="Pipe" />
            </div>
            <StyledSubTitle2 variant="white" paddingBottom="pb32">
              “Hasura is a fantastic way to create a data fetching layer to our database. It’s
              ultra-stable and often better at planning queries than ones we were writing
              ourselves.”
            </StyledSubTitle2>
            <div className="displayFlex">
              <div>
                <StyledDesc1 fontWeight="font_600" variant="white">
                  Peter Downs
                </StyledDesc1>
                <StyledDesc1 variant="white">Director of Engineering, Pipe</StyledDesc1>
              </div>
              <StyledDesc2
                className="textUppercase"
                variant="white"
                linkVariant="white"
                fontWeight="font_600"
              >
                <Link to="/case-studies/pipe/">
                  Read case study
                  <div className="arrow">
                    <ChevronRight />
                  </div>
                </Link>
              </StyledDesc2>
            </div>
          </div>
        </StyledCaseStudyWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperBlue>
  );
};

export default CaseStudyWrapper;
