import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import EnterpriseIndex from "../components/enterprise/EnterpriseIndex";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprise.png",
};
const canonicalUrl = "https://hasura.io/enterprise/";
const Enterprise = props => (
  <Layout location={props.location}>
    <Seo
      title="Hasura Enterprise | Advanced Security, Reliability & Performance"
      description="Hasura Enterprise has advanced Security & Reliability controls, SSO & APM integrations, self-hosted/on-premise deployment options, and more."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <EnterpriseIndex />
  </Layout>
);

export default Enterprise;
