import React, { useRef, useMemo } from "react";

import UserBrand from "../homepagenew/userbrand";

import SubNav from "../solutions/common/SubNav";
import TopBanner from "../solutions/common/TopBanner";
import Features from "../database/sqlServer/Features";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import SolutionsTraining from "./SolutionsTraining";
import GetStarted from "../solutions/common/GetStarted";
import CaseStudyWrapper from "../solutions/caching/CaseStudyWrapper";
import { removePaddBottom } from "../shared/CommonStyled";

import swiggy from "../imagescommon/swiggy-black.svg";
import netlify from "../imagescommon/netlify-black.svg";
import bbva from "../imagescommon/bbva-black.svg";
import airbus from "../imagescommon/airbus-black.svg";
import walmart from "../imagescommon/walmart-black.svg";
import virginia from "../imagescommon/virginia-black.svg";
import pipe from "../imagescommon/pipe-black.svg";
import atlassian from "../imagescommon/atlassian-black.svg";

import icons from "./images/icons.svg";

import security from "./images/security.svg";
import server from "./images/server.svg";
import performanceIcon from "./images/performance.svg";
import data_hub from "./images/data_hub.svg";
import howHasuraWorks from "./images/how-hasura-works.svg";

const userBrandState = [
  {
    imgSrc: swiggy,
    altText: "Swiggy",
  },
  {
    imgSrc: netlify,
    altText: "Netlify",
  },
  {
    imgSrc: bbva,
    altText: "BBVA",
  },
  {
    imgSrc: airbus,
    altText: "Airbus",
  },
  {
    break: true,
  },
  {
    imgSrc: walmart,
    altText: "Walmart",
  },
  {
    imgSrc: virginia,
    altText: "University of Virginia",
  },
  {
    imgSrc: atlassian,
    altText: "Atlassian",
  },
  {
    imgSrc: pipe,
    altText: "Pipe",
  },
];

const topBannerState = {
  title: "The data access layer for modern architectures",
  subTitle: "Enterprise ready version of Hasura with added security & performance features.",
  btnContent: "Talk to Sales",
  btnLink: "/contact-us/?type=hasuraenterprise",
  internalLink: true,
  list: [
    "Advanced Security & Reliability controls, SSO & APM integrations",
    "Self-hosted/on-premise deployment options",
    "24*7*365 support options and training from the core Hasura Team",
  ],
  width75: true,
  imgSrc: howHasuraWorks,
};

const cachingListState = [
  {
    id: "security",
    imgSrc: security,
    linkContent: "Security",
    title: "Security",
    desc: (
      <span>
        <div className="fontBold pb8">API Limiting</div>
        <ul className="discUl pb24">
          <li className="disc">Prevent abuse / DoS attacks on your GraphQL API</li>
          <li className="disc">
            Set role-based limits based on
            <ul className="discUl">
              <li className="disc">Rate: No. of requests per minute/hour</li>
              <li className="disc">Max. Query depth</li>
            </ul>
          </li>
          <li className="disc">
            Granular target segments based on any combination of session variables
          </li>
          <li className="disc">Manage QoS with user identity & session aware configuration</li>
        </ul>
        <div className="fontBold pb8">Allow listing</div>
        <ul className="discUl pb24">
          <li className="disc">
            Prevent scraping & abuse of your GraphQL API by allow-listing GraphQL operations
            actually used by your app
          </li>
          <li className="disc">
            Automatically build an allow-list by capturing operations during CI
          </li>
          <li className="disc">
            Enable warn mode to only log unknown operations in production without preventing their
            execution
          </li>
        </ul>
        <div className="fontBold pb8">Team management</div>
        <ul className="discUl">
          <li className="disc">
            Member specific login to Hasura project with granular privileges
            <ul className="discUl">
              <li className="disc">Run GraphQL queries</li>
              <li className="disc">View analytics & metrics</li>
              <li className="disc">Manage team members</li>
            </ul>
          </li>
          <li className="disc">SSO integrations available on request</li>
        </ul>
      </span>
    ),
  },
  {
    id: "reliability",
    imgSrc: server,
    linkContent: "Reliability",
    title: "Reliability",
    desc: (
      <span>
        <div className="fontBold pb8">Monitoring and analytics</div>
        <ul className="discUl pb24">
          <li className="disc">Troubleshoot errors & drill-down into individual operations</li>
          <li className="disc">
            Detect patterns across GraphQL operations
            <ul className="discUl">
              <li className="disc">Slowest operations</li>
              <li className="disc">Commonly-seen errors</li>
              <li className="disc">Error-prone operations</li>
            </ul>
          </li>
          <li className="disc">Integrations with external logging & APM tools</li>
          <li className="disc">
            Observability into your GraphQL stack, including websocket connections and subscriptions
          </li>
          <li className="disc">API access to Hasura-generated metrics</li>
        </ul>
        <div className="fontBold pb8">Regression tests</div>
        <ul className="discUl">
          <li className="disc">
            Replay operations from the regression suite in dev & staging
            <ul className="discUl">
              <li className="disc">
                During Dev: run manually via console and get instant feedback
              </li>
              <li className="disc">Error-prone operations</li>
            </ul>
          </li>
          <li className="disc">Automatically create a regression suite using production traffic</li>
          <li className="disc">
            Prevent breaking changes & regressions automatically by replaying production traffic on
            dev/staging to capture schema & configuration changes
          </li>
        </ul>
      </span>
    ),
  },
  {
    id: "performance",
    imgSrc: performanceIcon,
    linkContent: "Performance",
    title: "Performance",
    desc: (
      <span>
        <div className="fontBold pb8">Caching</div>
        <ul className="discUl pb24">
          <li className="disc">
            Instantly enable caching of frequent and expensive API calls (GraphQL queries) without
            writing any code
          </li>
          <li className="disc">
            Cache public data & cache private data based on session and authorization rules
          </li>
          <li className="disc">TTL based cache invalidation</li>
        </ul>
        <div className="fontBold pb8">Read-replica Support</div>
        <ul className="discUl">
          <li className="disc">
            Automatic routing for queries, subscriptions and mutations across master and
            read-replicas.
          </li>
          <li className="disc">
            Advanced configuration options for setting connection pools per master/replica.
          </li>
          <li className="disc">Read Replica Support for enhanced performance and scalability.</li>
        </ul>
      </span>
    ),
  },
  {
    id: "custom-integrations",
    imgSrc: data_hub,
    linkContent: "Custom Integrations",
    title: "Custom Integrations",
    desc: (
      <span>
        <div className="fontBold pt16">
          Integrate Hasura into your existing systems and developer workflows.
        </div>
        <ul className="discUl">
          <li className="disc">
            SSO integration with Active Directory Federated Services, AWS IAM or any identity
            provider to securely access and manage Hasura.
          </li>
          <li className="disc">
            APM integrations Datadog, Prometheus, etc. to stream Monitoring & Analytics data to a
            central logs/APM service.
          </li>
          <li className="disc">
            Alerting integrations with services like PagerDuty, Opsgenie, etc.
          </li>
        </ul>
      </span>
    ),
    panelImg: icons,
  },
];

const EnterpriseIndex = () => {
  const featuresRef = useRef(null);
  const securityRef = useRef(null);
  const reliabilityRef = useRef(null);
  const performanceRef = useRef(null);
  const customIntegrationsRef = useRef(null);

  const sectionRefs = useMemo(
    () => [{ name: "Features", section: "features", ref: featuresRef }],
    []
  );

  const openSourceSection = {
    security: { section: "security", ref: securityRef },
    reliability: { section: "reliability", ref: reliabilityRef },
    performance: { section: "performance", ref: performanceRef },
    "custom-integrations": { section: "custom-integrations", ref: customIntegrationsRef },
  };

  return (
    <>
      <SubNav
        sectionRefs={sectionRefs}
        pageName="Hasura Enterprise Edition"
        pagePath="/enterprise/"
        currentDropDownMenu="products"
        subNavBtnLink="https://cloud.hasura.io/signup?pg=caching&plcmt=sub-header&cta=get-started-now&tech=default"
      />
      <TopBanner topBannerState={topBannerState} paddBottom={false} />
      <UserBrand userBrandState={userBrandState} />
      <div id="features" ref={featuresRef}>
        <Features
          title="Features"
          desc=""
          sections={openSourceSection}
          tabListState={cachingListState}
        />
      </div>
      <SolutionsTraining />
      <CaseStudyWrapper />
      <StyledSectionWrapper css={removePaddBottom}>
        <GetStarted
          title="Ready to get started with Hasura?"
          btnContent="Try on Hasura Cloud"
          btnLink="https://cloud.hasura.io/signup?pg=opensource&plcmt=body&cta=try-on-hasura-cloud&tech=default"
        />
      </StyledSectionWrapper>
    </>
  );
};

export default EnterpriseIndex;
