import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { Icon } from '../../globals/icons';
import { StyledSubTitle1, StyledSubTitle2, StyledDesc2 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

import help from "./images/help.svg";
import core_concepts from "./images/core_concepts.svg";
import tutorials from "./images/tutorials.svg";


const StyledSolutionsTrainingWrapper = styled.div`
  .mAlign {
    text-align: center;
    padding-bottom: 80px;
  }
  .trainingListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 48px;
    .cardList {
      border: 1px solid ${COLORS.grey_20};
      border-radius: 8px;
      .listHeader {
        background-color: ${COLORS.grey_4};
        border-bottom: 1px solid ${COLORS.grey_20};
        padding: 32px;
        .mHeight {
          min-height: 109px;
        }
        .flexCenter {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          img {
            margin-right: 24px;
          }
        }
      }
      .p32 {
        padding: 32px;
      }
    }
  }
  ${mq.between("md", "lg")} {
    .trainingListWrapper {
      grid-template-columns: 1fr 1fr;
      .cardList {
        .listHeader {
          .mHeight {
            min-height: 103px;
          }
        }
      }
    }
  }
  ${mq.below.md} {
    .mAlign {
      text-align: left;
      padding-bottom: 32px;
    }
    .trainingListWrapper {
      grid-template-columns: 1fr;
      grid-gap: 32px;
      .cardList {
        .listHeader {
          padding: 24px;
          .mHeight {
            min-height: unset;
          }
        }
        .p32 {
          padding: 24px;
        }
      }
    }
  }
`;

const solutionsState = [
  {
    title: "Support",
    icon: help,
    description: "Collaborate with Hasura to empower your team to rapidly build and seamlessly manage your applications.",
    list: ["Development support: SLA-based support & 1x1 consulting sessions to turbocharge application development.", "Production support: On-call support to help troubleshoot & fix issues in production."],
  },
  {
    title: "Solutions",
    icon: core_concepts,
    description: "Accelerate evaluation & adoption journeys with integrations, architecture, development, and delivery.",
    list: ["Evaluation & Architecture: set up a PoC to help you evaluate how to use Hasura with your existing architecture and stack.", "Adoption: development and delivery services."],
  },
  {
    title: "Training",
    icon: tutorials,
    description: "Equip your team with knowledge and skills across the stack to rapidly build world-class applications.",
    list: ["Workshops: introduce GraphQL and modern architecture patterns to your team with interactive workshops.", "Training: customized training solutions and content for your backend, frontend and DevOps/SRE teams."],
  },
]
const SolutionsTraining = () => {
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledSolutionsTrainingWrapper>
          <StyledSubTitle1 className="mAlign">Support, solutions and training</StyledSubTitle1>
          <div className="trainingListWrapper">
            {
              solutionsState.map((item, index) => (
                <div key={index} className="cardList">
                  <div className="listHeader">
                    <div className="flexCenter">
                      <img src={item.icon} alt={item.title} />
                      <StyledSubTitle2>{item.title}</StyledSubTitle2>
                    </div>
                    <StyledDesc2 className="mHeight">{item.description}</StyledDesc2>
                  </div>
                  <div className="p32">
                    <StyledDesc2>
                      <ul>
                        {
                          item.list.map((list, subIndex) => (
                            <li key={subIndex}><Icon className="leftIcon" variant="check_mark" color="sky_80" size="sm" />{list}</li>
                          ))
                        }
                      </ul>
                    </StyledDesc2>
                  </div>
                </div>
              ))
            }
          </div>
        </StyledSolutionsTrainingWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  )
}

export default SolutionsTraining;
